import { useStaticQuery, graphql } from "gatsby";

export const useHeader = () => {
  const { menu,logo } = useStaticQuery(
    graphql`
      query MyQuery {
        menu: allWpMenuItem(
          filter: { locations: { eq: PRIMARY }, parentId: { eq: null } }
        ) {
          edges {
            node {
              id
              uri
              url
              title
              path
              label
              parentId
              target
              childItems {
                nodes {
                  id
                  url
                  uri
                  title
                  path
                  parentId
                  label
                  target
                  childItems {
                    nodes {
                      id
                      url
                      uri
                      title
                      path
                      parentId
                      label
                      target
                    }
                  }
                }
              }
            }
          }
        }
        logo: wp {
          siteOptions {
            siteOptions {
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: WEBP, placeholder: NONE)
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return {
    menu: menu?.edges,
    logo: logo?.siteOptions?.siteOptions?.logo?.localFile,
  };
};
