import React, { useEffect, useState } from "react";
import Arrows from "../../images/icons/arrows";
import LoadingBtn from "./LoadingBtn/LoadingBtn";
import "./Button.css";
import IconCheck from "../../images/png/check.png";
interface ButtonInterface {
  backgroundColor?: string;
  textColor?: string;
  hoverBackgroundColor?: string;
  textHoverColor?: string;
  maxWidth?: string;
  padding?: string;
  type?: "button" | "submit";
  disabled?: boolean;
  disabledStyle?: string;
  label: string;
  textSize?: string;
  classCustom?: string;
  icon?: React.ReactNode;
  sizeIcon?: string;
  lineLabel?: string;
  noIcon?: boolean;
  onClick?: () => void;
  leftIcon?: boolean;
  refButton?: any;
  loading?: boolean;
  success?: boolean;
  justifyContent?: string;
  typeHover?: string;
}
function Button(props: ButtonInterface) {
  const {
    type,
    onClick,
    label,
    maxWidth = "max-w-[158px]",
    padding = "py-[12px]",
    hoverBackgroundColor = "bg-dark-blue",
    backgroundColor = "bg-primary",
    textColor = "text-gray-50",
    textHoverColor = "hover:text-gray-50",
    textSize = "text-l-semibold tracking-1.5 ",
    disabled,
    disabledStyle = `bg-[#D0D5DD] border-gray-500 text-neutral-color-50 cursor-auto`,
    icon = <Arrows color={"none"} />,
    classCustom,
    lineLabel,
    sizeIcon = "ms-[12px] w-[16px] h-[16px] mr-[3px]",
    noIcon,
    leftIcon = false,
    refButton,
    loading,
    success,
    justifyContent = "justify-center",
    typeHover,
  } = props;

  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (success) {
      setShowIcon(true);
      timeoutId = setTimeout(() => {
        setShowIcon(false);
      }, 5000); // 5 seconds
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [success]);

  return (
    <button
      ref={refButton}
      type={type}
      className={`
      relative ${padding} ${backgroundColor} ${maxWidth} w-full flex items-center ${justifyContent} common-button common-button--${
        leftIcon ? "left" : "right"
      } ${textColor} font-medium ${textHoverColor} ${classCustom} ${
        disabled ? disabledStyle : ""
      }`}
      onClick={onClick}
    >
      {loading ? (
        <LoadingBtn />
      ) : showIcon ? (
        <img src={IconCheck} />
      ) : (
        <>
          <span
            className={`absolute inset-[0] w-full h-full flex items-center justify-center origin-top common-button-overlay`}
          ></span>
          <span
            className={`z-10 button-text flex items-center leading-6 font-semibold align-middle ${textSize}`}
          >
            {icon && leftIcon && (
              <span className={`block ${sizeIcon} icon-hover`}>{icon}</span>
            )}
            <span
              className={`${lineLabel} ${
                typeHover === "PriAndSec"
                  ? "labelHover"
                  : typeHover === "Tertiary"
                  ? "labelHoverTer"
                  : ""
              } `}
              dangerouslySetInnerHTML={{ __html: label }}
            />
            {icon && !noIcon && !leftIcon && (
              <span
                className={`block ${sizeIcon} ${
                  typeHover === "PriAndSec"
                    ? "icon-hover"
                    : typeHover === "Tertiary"
                    ? "icon-hoverTer"
                    : ""
                }`}
              >
                {icon}
              </span>
            )}
          </span>
        </>
      )}
    </button>
  );
}

export default Button;
