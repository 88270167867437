import React, { Suspense, useEffect, useLayoutEffect, useRef, useState } from "react";
import ArrowDown from "../../../images/icons/ArrowDown";
import Arrows from "../../../images/icons/arrows";
import { Link, navigate } from "gatsby";
import Button from "../../Button";
import Global from "../../../images/icons/Global";
import ArrowMenuActive from "../../../images/icons/ArrowMenuActive";

type TMegaMenu = {
  menu: any;
  handleMouseEnter?: Function;
  handleMouseLeave?: Function;
  hoverState: any;
  refBtn?: any
};

function MegaMenu({
  menu,
  handleMouseEnter,
  handleMouseLeave,
  hoverState,
  refBtn
}: TMegaMenu) {
  const mouseEnter = (node: any) => {
    if (handleMouseEnter) {
      handleMouseEnter(node);
    }
  };

  const mouseLeave = () => {
    if (handleMouseLeave) {
      handleMouseLeave();
    }
  };

  return (
    <>
      {menu?.map(({ node }: any, index: any) => (
        <li
          key={index}
          onMouseEnter={() => mouseEnter(node)}
          onMouseLeave={mouseLeave}
          className={`
        ${node?.id === hoverState?.id &&
            hoverState?.childItems?.nodes?.length > 0 &&
            "border-r-border-color border-l-border-color bg-nav-hover"
            }
          lg992:w-full
          h-header
          flex
          items-center
          justify-center
          cursor-pointer
          border-r
          border-l
          border-solid
          border-[transparent]
        hover:border-r-border-color 
        hover:border-l-border-color
        hover:bg-nav-hover
        last:bg-primary
        last:group
        hover:transition-colors
        transition-item
        lg1200:text-l-medium
        text-s-medium
        text-gray-100  "}
        `}
        >
          <Link
            to={node.uri}
            className={`relative w-full h-full flex items-center justify-center ${index !== menu.length - 1 && "3xl:px-[50px] xl1440:px-[20px] xl1440:whitespace-nowrap"} `}
          >
            {node?.id === hoverState?.id &&
              hoverState?.childItems?.nodes?.length > 0 && <div className="absolute bottom-[-1px] fade-in-bottom"><ArrowMenuActive /></div>}
            {index === menu.length - 1 ? (
              <Suspense fallback={<></>}>
                <Button
                  refButton={refBtn}
                  label={node?.label}
                  classCustom="h-full w-full px-0 xl1440:px-[20px] 3xl:px-[50px] xl1440:whitespace-nowrap"
                  maxWidth=""
                  lineLabel="
                  tracking-[1.5px] 
                  lg1200:text-l-medium
                  text-s-medium
                  "
                  typeHover="PriAndSec"
                />
              </Suspense>
            ) : (
              // <>
              //  {node?.label}
              // <span className="ml-[8px]">
              //   <Arrows />
              // </span>
              // </>
              <>
                {node?.label === "icon-global" ? <Global /> : node?.label}
                {node?.childItems?.nodes?.length > 0 && (
                  <span className="ml-[8px]">
                    <ArrowDown />
                  </span>
                )}
              </>
            )}
          </Link>
        </li>
      ))}
    </>
  );
}

export default MegaMenu;
