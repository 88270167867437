import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { client } from "./src/apollo/client";
import Layout from "./src/components/layout/layout";
import "./src/style/global.css";
import { siteKey } from "./src/utils/constant";

export const wrapPageElement = ({ element, props }) => {
  if (
    props &&
    props.pageContext &&
    props.pageContext.redirect &&
    props.pageContext.redirect[window.location.pathname]
  ) {
    window.location.href = props.pageContext.redirect[window.location.pathname];
  }
  return <Layout {...props}>{element}</Layout>;
};
export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <GoogleReCaptchaProvider
        reCaptchaKey={siteKey}
        scriptProps={{ defer: true }}
      >
        {element}
      </GoogleReCaptchaProvider>
    </ApolloProvider>
  );
};

export const replaceHydrateFunction = () => {
  return (element: any, container: any) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
function initClarity() {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.defer = true;
  script.innerHTML = `(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "fufmljeo2o");`;
  document.head.appendChild(script);
}
function loadClarity(event: any) {
  initClarity();
  event.currentTarget.removeEventListener(event.type, loadClarity);
}
export const onClientEntry = () => {
  if (window.innerWidth < 1024) {
    document.addEventListener("touchstart", loadClarity);
  } else {
    document.addEventListener("mousemove", loadClarity);
  }
};
