import React, { useEffect, useRef, useState } from "react";
import ArrowDown from "../../../images/icons/ArrowDown";
import Arrows from "../../../images/icons/arrows";
import MenuItem from "../MenuItem/MenuItem";
import { navigate } from "gatsby";
import Global from "../../../images/icons/Global";
import IconCheck from "../../../images/icons/IconCheck";


type TMenuMobile = {
  menuMobile: any;
  onClick?: Function;
  image?: string;
  handleLeave: Function;
  expandMenu: boolean;
};

function MenuMobile({ menuMobile, expandMenu, onClick, image, handleLeave }: TMenuMobile) {
  const [show, setShow] = useState(-1);
  const [globalActive, setGlobalActive] = useState(0);

  // const [menu, setMenu] = useState<any>()

  // useEffect(() => {
  //   let menuM = menuMobile.filter(({node}:any) => node.label !== "HOME")
  //   setMenu(menuM)
  // }, [])

  useEffect(() => {
    if (!expandMenu) {
      setShow(-1)
    }
  }, [expandMenu])
  const clickItem = (index: number, node: any) => {
    if (node?.childItems?.nodes?.length === 0) {
      navigate(node.uri);
      handleLeave();
      // location.history.replace('/new-page/')
    } else {
      if (show !== index) {
        setShow(index);
        if (onClick) {
          onClick(node)
        }
      } else {
        setShow(-1);
      }
    }
  };

  const isIosDevice =
    typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.platform &&
    (/iPad|iPhone|iPod/.test(window.navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))

  return (
    <>
      {menuMobile?.map(({ node }: any, index: any) => (
        <div className={`mobile-menu-child ${index === show ? "active" : ""}`} key={index}>
          <div
            onClick={() => clickItem(index, node)}
            className={`
              h-header
              flex
              items-center
              justify-center
              tracking-[1.5px]
              cursor-pointer
              text-l-medium
              xl:l-semiboldCus 
              lg1200:text-l-medium
              text-secondary
              ${index === menuMobile.length - 1 ? "bg-primary text-white" : "bg-gray-50"
              }
            `}
          >
            {node?.label === "icon-global" ? <Global color="#162035" /> : node?.label}
            {index === menuMobile.length - 1 && (
              <span className="ml-[8px]">
                <Arrows color={"white"} />
              </span>
            )}
            {node?.childItems?.nodes?.length > 0 && (
              <span className="icon-arrow ml-[8px]">
                <ArrowDown color={"#162035"} />
              </span>
            )}
          </div>
          <div className={`bg-neutral-color-background-1 menu-mobile-child-l2`} style={{}}>
            {show === index && (
              <>
                {node?.label === "icon-global" ?
                  <div className="px-[16px] py-[32px]">
                    {node.childItems?.nodes.map((e: any, index: number) => (
                      // <div onClick={() => setGlobalActive(index)}
                      <a href={e.uri}
                        className="flex items-center justify-between  text-gray-700 text-h6 font-medium py-[16px] border-b border-solid border-border-color cursor-pointer">
                        <span className={`${globalActive === index && "text-primary"}`}>{e.label}</span>
                        {globalActive === index && <IconCheck />}
                      </a>
                    ))}</div>
                  :
                  <>
                    {node.childItems?.nodes.map((e: any) => (
                      <MenuItem item={e} image={image} handleNextPage={handleLeave} />
                    ))}
                  </>
                }
              </>
            )}
          </div>
        </div>
      ))}
      {(isIosDevice && show !== -1) &&
        <div className="h-[100px]"></div>
      }
    </>
  );
}

export default MenuMobile;
