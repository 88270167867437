import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import fetch from "isomorphic-fetch";
const httpLink = createHttpLink({
  uri: `https://be.arrowhitech.com/graphql`,
  fetch: fetch,
  fetchOptions: {
    mode: "cors",
  },
  headers: {
    "Accept-Encoding": "gzip, deflate, br",
    "Transfer-Encoding": "chunked",
    "Content-Type": "application/json; charset=utf-8",
    Connection: "keep-alive",
    Accept: "application/json",
  },
});
export const client = new ApolloClient({
  link: ApolloLink.from([httpLink]),
  cache: new InMemoryCache(),
});
