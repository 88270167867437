import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import ArrowDown from "../../../images/icons/ArrowDown";
import Arrows from "../../../images/icons/arrows";
import MoreHorizontal from "../../../images/icons/MoreHorizontal";
import { Link } from "gatsby";
import "../Header.css";
import ArrowMenu from "../../../images/icons/ArrowMenu";

function MenuItem({ item, image, handleNextPage }: any) {
  return (
    <div
      className="w-full ml-auto mr-auto last:pb-32 lg992:last:pb-0
    "
    >
      <div
        className={`${(item.label === "Consultancy" || item.label === "Software Development Services") ? "lg1200:py-[48px] lg1200:pb-0" : "lg1200:py-[48px]"} lg1200:px-0 px-[16px] pb-0 ${item.label === "Insights" ? 'pt-16' : 'pt-[32px]'} `}
      >
        <div className="w-full gap-x-[4.5em] gap-y-[4em] grid-cols grid-rows-auto grid-cols-2">
          {item?.label?.includes("mediaItem") ? (
            <>
              {image && (
                <img
                  src={image}
                  alt="image insight"
                  className="lg992:max-h-[316px] w-full object-cover"
                />
              )}
            </>
          ) : (
            <>
              {item.label !== "Insights" && (
                <>
                  {item.url === "#" ? (
                    <span className="text-secondary text-menuTitle mb-[16px] text-left">
                      {item.label}
                    </span>
                  ) : (
                    <Link
                      to={item.url}
                      onClick={() => handleNextPage()}
                      className="text-secondary text-menuTitle mb-[16px] text-left border-b border-solid border-secondary lg992:hover:text-primary
                      lg992:hover:border-b-primary"
                    >
                      {item.label}
                    </Link>
                  )}
                </>
              )}
              <div
                className={`${item.label === "Software Development Services"
                  ? "custom-grid lg992:pt-16 pt-20"
                  : "flex flex-col items-stretch lg992:pt-16"
                  }`}
              >
                {item.childItems.nodes.map((el: any) => (
                  <Link
                    target={el.target || "_self"}
                    to={el.uri}
                    onClick={() => handleNextPage()}
                    className="
                      w-full 
                      min-h-[48px]
                      text-menu
                      bg-center 
                      border-b 
                      border-solid 
                      border-neutral-color-background-3
                      flex 
                      justify-between 
                      items-center 
                      p-2 
                      overflow-hidden
                      cursor-pointer
                      text-gray-900
                      lg992:hover:text-primary
                      lg992:hover:border-b-primary
                      group
                      hover-menuItem
                      "
                  >
                    <p
                      className="
                        text-left
                        mr-0
                        flex
                        items-center
                        gap-x-[8px]
                        "
                    >
                      {/* <span className="hidden lg992:group-hover:block">
                        <MoreHorizontal />
                      </span> */}
                      <span className="pr-[8px] break-words overflow-hidden line-clamp-1">
                        {" "}
                        {el.label}
                      </span>
                    </p>
                    <i className="ml-4">
                      <ArrowMenu />
                    </i>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MenuItem;
