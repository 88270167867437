import { useState, useEffect } from "react";

function getWindowDimensions() {
  const windows = window
  const { innerWidth: width, innerHeight: height } = windows;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState<any>();
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    setWindowDimensions(getWindowDimensions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    width: windowDimensions?.width,
    height: windowDimensions?.height,
  };
}
