import React from "react";

function ArrowMenuActive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
    >
      <path
        d="M7.40687 1.70938L7 1.13977L6.59313 1.70938L1.59313 8.70938L1.02841 9.5H2H12H12.9716L12.4069 8.70938L7.40687 1.70938Z"
        fill="white"
      />
    </svg>
  );
}

export default ArrowMenuActive;
