import React from 'react';
import "./LoadingBtn.css"

function LoadingBtn() {
  return (
    <div className="snippet" data-title="dot-spin">
      <div className="stage">
        <div className="dot-spin"></div>
      </div>
    </div>
  )
}

export default LoadingBtn