import { gql, useLazyQuery } from "@apollo/client";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimension";
import MenuIcon from "../../images/icons/MenuIcon";
import { useHeader } from "../../queries/useHeader";
import "./Header.css";
import MegaMenu from "./MegaMenu/MegaMenu";
import MenuItem from "./MenuItem/MenuItem";
import MenuMobile from "./MenuMobile/MenuMobile";
import { Link } from "gatsby";
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";
import IconCheck from "../../images/icons/IconCheck";

function Header({ myRef }: any): JSX.Element {
  const { menu, logo } = useHeader();
  const image = getImage(logo) as IGatsbyImageData;
  const [isHovering, setIsHovering] = useState(false);
  const [menuItem, setMenuItem] = useState<any>(null);
  const [trans, setTrans] = useState<boolean>(false);
  const [openMenuMobile, setOpenMenuMobile] = useState<boolean>(false);
  const [imageInsight, setImageInsight] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [globalActive, setGlobalActive] = useState(0);
  const { width } = useWindowDimensions();
  const refBtn = useRef<any>();
  const [mr, setMr] = useState();
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  useEffect(() => {
    const width = refBtn.current.clientWidth;
    setMr(width);
  }, [width]);

  useDisableBodyScroll(openMenuMobile === true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getMediaItem = (node: any) => {
    let item = node?.childItems?.nodes;
    const regex = /\d+/g;
    let result = "";
    if (item && item?.length > 0) {
      let i = item.filter((el: any) => el.label.includes("mediaItem"))[0];
      result = i?.label?.match(regex);
    }
    return Number(result);
  };

  const [getImageMenu, { data, loading }] = useLazyQuery(
    gql`
      query GET_IMAGE_MENU($id: ID!) {
        mediaItem(id: $id, idType: DATABASE_ID) {
          sourceUrl
        }
      }
    `,
    {
      onCompleted: (data) => {
        setImageInsight(data.mediaItem.sourceUrl);
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    let temp = setTimeout(() => {
      setTrans(false);
    }, 500);
    return () => {
      clearTimeout(temp);
    };
  }, [menuItem?.id]);

  const handleGetImage = (node: any) => {
    if (node.label === "EXPLORE" && imageInsight === "") {
      const id = getMediaItem(node); //684
      getImageMenu({
        variables: {
          id: id,
        },
      });
    }
  };

  const handleMouseEnter = (node: any) => {
    setIsHovering(true);
    setTrans(true);
    setMenuItem(node);
    handleGetImage(node);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleLeave = () => {
    setMenuItem(null);
    setOpenMenuMobile(false);
  };

  const handleOpenMenuMobile = () => {
    setOpenMenuMobile(!openMenuMobile);
  };

  useEffect(() => {
    const isSeaActive = url.includes("ahttech-sea");

    if (isSeaActive) {
      setGlobalActive(1);
    } else {
      setGlobalActive(0);
    }
  }, [url]);

  return (
    <header
      className="w-full mx-auto z-[51] fixed top-0"
      onMouseLeave={handleLeave}
      ref={myRef}
    >
      <div
        className={` bg-neutral-color-text-title text-gray-900 flex items-center justify-between
        header-custom
        h-header
        border-b
        border-solid
        border-border-color
        ${isScrolled ? "scrolled" : ""}
    `}
      >
        <div className="flex w-full h-full">
          <div
            className="
              xl1440:w-[20%]
              3xl:w-[23.33%]
              lg992:w-[15%]
              S300-992:w-1/3
              flex items-center
              justify-center
              border-r
              border-solid
              border-border-color
              bg-[rgba(160,188,255,.05)]
              hover:bg-nav-hover
              "
          >
            {logo && (
              <Link
                aria-label="logo-AHT"
                to="/"
                className="xl1440:w-[221px]
                cursor-pointer
                w-[100px]
                object-cover"
              >
                <GatsbyImage
                  className="w-full h-full object-cover"
                  image={image}
                  alt="logo header"
                />
              </Link>
            )}
          </div>
          <nav className="flex-1 S300-992:w-1/2 items-center navbar">
            <ul className="flex S300-992:hidden  w-full h-full items-center justify-between">
              <MegaMenu
                hoverState={menuItem}
                menu={menu}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                refBtn={refBtn}
              />
            </ul>
            <div className="lg992:hidden grid w-full h-full grid-cols-2">
              <div className="col-span-1"></div>
              <div
                onClick={handleOpenMenuMobile}
                className="col-span-1 flex items-center justify-center
                 bg-[rgba(160,188,255,.05)]
                 border-l
                 border-solid
                 border-border-color    
              "
              >
                <MenuIcon />
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div
        className={`lg992:hidden block menu-mobile bg-gray-50 relative  ${openMenuMobile ? "active" : "unactive"
          }`}
      >
        {/* {openMenuMobile && ( */}
        <MenuMobile
          menuMobile={menu}
          image={imageInsight}
          onClick={handleGetImage}
          handleLeave={handleLeave}
          expandMenu={openMenuMobile}
        />
        {/* )} */}
      </div>
      <div
        style={(menuItem?.label === "icon-global" || menuItem?.label === "EXPLORE") ? { marginRight: mr + "px" } : {}}
        className={`bg-gray-50 lg992:block  hidden bs-header
        ${trans && "fade-in-bottom"} 
        ${menuItem?.label === "icon-global" &&
          `w-[316px] ml-auto px-[48px] py-[32px] bs-header`
          }
           ${menuItem?.label === "EXPLORE" &&
          `w-[600px] ml-auto px-[48px] py-[32px] bs-header`
          }
          `}
      >
        {menuItem?.label === "icon-global" ? (
          <div className={`flex flex-col`}>
            {menuItem?.childItems?.nodes.map((e: any, index: number) => (
              //onClick={()=> setGlobalActive(index)}
              // <Link
              //   to={e.uri}
              //   onClick={handleLeave}
              //   className={`flex items-center text-gray-900 text-h6 font-medium py-[16px] border-b border-solid border-border-color cursor-pointer
              // ${
              //   globalActive !== index
              //     ? "justify-start lg992:hover:text-secondary lg992:hover:border-b-primary group"
              //     : "justify-between"
              // }
              // `}
              // >
              <Link
                to={e.uri}
                onClick={handleLeave}
                className={`flex items-center text-gray-900 text-h6 font-medium py-[16px] border-b border-solid border-border-color cursor-pointer justify-start lg992:hover:text-secondary lg992:hover:border-b-primary group
              ${globalActive !== index
                    ? "justify-start lg992:hover:text-secondary lg992:hover:border-b-primary group"
                    : "justify-between"
                  }
              `}
              >
                <span
                  className={`${globalActive === index && "text-primary"
                    } transition-custom`}
                >
                  {e.label}
                </span>
                {globalActive === index && <IconCheck />}
              </Link>
            ))}
          </div>
        ) : menuItem?.childItems?.nodes?.length < 4 ? (
          <div
            className={`grid
            ${menuItem?.childItems?.nodes?.length === 2
                ? "lg1200:grid-cols-2 lg992:grid-cols-2 grid-cols-1"
                : menuItem?.label === "EXPLORE"
                  ? "grid-cols-1"
                  : "lg1200:grid-cols-3 lg992:grid-cols-2 grid-cols-1"
              }
              ${menuItem?.label === "EXPLORE"
                ? "px-[16px] gap-x-[16px] 3xl:gap-x-[32px] xl1440:gap-x-[24px]"
                : "3xl:px-[244px] 3xl:gap-x-[32px] xl1440:px-[156px]  xl1440:gap-x-[24px] px-[16px] gap-x-[16px]"
              }
              `}
          >
            {menuItem?.childItems?.nodes.map((e: any) => (
              <MenuItem
                item={e}
                image={imageInsight}
                handleNextPage={handleLeave}
              />
            ))}
          </div>
        ) : (
          <div
            className={`grid
            lg1200:grid-cols-4 lg992:grid-cols-3 grid-cols-1
            3xl:px-[244px]
            3xl:gap-x-[32px]
            xl1440:px-[156px]
            xl1440:gap-x-[24px]
            px-[16px]
            gap-x-[16px]
        " ${menuItem && "menuScroll"}`}
          >
            <div className="grid-span-1">
              <div className="flex flex-col justify-between">
                {menuItem?.childItems?.nodes.slice(0, 2).map((e: any) => (
                  <MenuItem
                    item={e}
                    image={imageInsight}
                    handleNextPage={handleLeave}
                  />
                ))}
              </div>
            </div>
            <div className="col-span-2">
              <div className="grid lg992:grid-cols-1 grid-col-1 3xl:gap-x-[32px] xl1440:gap-x-[24px] gap-x-[16px]">
                {menuItem?.childItems?.nodes.slice(2, 4).map((e: any) => (
                  <MenuItem
                    item={e}
                    image={imageInsight}
                    handleNextPage={handleLeave}
                  />
                ))}
              </div>
            </div>
            <div className="grid-span-1">
              <div className="grid lg992:grid-cols-1 grid-col-1 3xl:gap-x-[32px] xl1440:gap-x-[24px] gap-x-[16px]">
                {menuItem?.childItems?.nodes
                  .slice(4, menuItem?.childItems?.nodes.length)
                  .map((e: any) => (
                    <MenuItem
                      item={e}
                      image={imageInsight}
                      handleNextPage={handleLeave}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </header >
  );
}
export default Header;
