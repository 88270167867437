import React from 'react'

function ArrowDown({color ="#F9FAFB",className}:any) {
  return (
    <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 6L8.5 10L12.5 6" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
  )
}

export default ArrowDown