exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-case-studies-detail-case-studies-detail-tsx": () => import("./../../../src/templates/CaseStudiesDetail/caseStudiesDetail.tsx" /* webpackChunkName: "component---src-templates-case-studies-detail-case-studies-detail-tsx" */),
  "component---src-templates-ebooks-detail-ebooks-detail-tsx": () => import("./../../../src/templates/EbooksDetail/EbooksDetail.tsx" /* webpackChunkName: "component---src-templates-ebooks-detail-ebooks-detail-tsx" */),
  "component---src-templates-events-detail-events-detail-tsx": () => import("./../../../src/templates/EventsDetail/EventsDetail.tsx" /* webpackChunkName: "component---src-templates-events-detail-events-detail-tsx" */),
  "component---src-templates-news-detail-news-detail-tsx": () => import("./../../../src/templates/NewsDetail/newsDetail.tsx" /* webpackChunkName: "component---src-templates-news-detail-news-detail-tsx" */),
  "component---src-templates-pages-page-tsx": () => import("./../../../src/templates/pages/page.tsx" /* webpackChunkName: "component---src-templates-pages-page-tsx" */),
  "component---src-templates-post-detail-post-detail-tsx": () => import("./../../../src/templates/postDetail/postDetail.tsx" /* webpackChunkName: "component---src-templates-post-detail-post-detail-tsx" */)
}

